import React, { useEffect, useState } from 'react'
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import {
  Store,
  ViewModule,
  HowToReg,
  Place,
  BubbleChart,
} from '@material-ui/icons'
import CountUp from 'react-countup'
import { Animated } from 'react-animated-css'

import welcome from 'assets/img/welcomeadmin.png'

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js'

const useStyles = makeStyles(styles)

export default function Dashboard() {
  const classes = useStyles()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div>
      <GridContainer>
        {/* <Grid item xs={12} sm={12}>
          <center>
            {error ? (
              <Alert severity="error">Error en con la conexión</Alert>
            ) : (
              ""
            )}
          </center>
        </Grid>*/}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <ViewModule />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}*/
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}*/
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <HowToReg />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}*/
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Place />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  /*onComplete={onComplete}
          onStart={onStart}*/
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <div className={classes.carDashboard}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Animated
              animationIn="fadeIn"
              animationInDuration={200}
              isVisible={true}
            >
              <div className={classes.titleCard}>
                <BubbleChart className={classes.iconTheme} /> Bienvenido(a) al
                panel administrativo
              </div>
              <br></br>
              <div className={classes.fontParagraph}>
                <b>PSICOMÉTRICOS ECUADOR</b> es un Experto en Provisión de Técnicas para la
                Selección de Personal, mejoramiento continuo y el asesoramiento
                durante el proceso; además del manejo del riesgo que esto
                representa.
              </div>
            </Animated>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: '40px 0' }}>
            <Animated
              animationIn="fadeIn"
              animationInDuration={200}
              isVisible={true}
            >
              <img src={welcome} alt="welcome" className={classes.cardImg} />
            </Animated>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
