import React, { Fragment, useState, useContext, forwardRef } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { Redirect } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { Cancel, Save, SaveAlt } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import Snackbar from '@material-ui/core/Snackbar'
import Papa from 'papaparse'
import axios from 'axios'

import Slide from '@material-ui/core/Slide'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import { backdropStyle } from 'assets/jss/material-dashboard-react/components/backdropStyle'

const useStyles = makeStyles(cardBodyStyle)
const useStylesBackdrop = makeStyles(backdropStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function EnableAssignMasivamente(props) {
  const classes = useStyles()
  const classesBackdrop = useStylesBackdrop()
  const [rowsImport, setRowsImport] = useState([])
  const [disabledMasive, setDisabledMasive] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [open, setOpen] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const changeHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const rowsArray = []
          const valuesArray = []

          // Iterating data to get column name and their values
          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d))
            valuesArray.push(Object.values(d))
          })

          setRowsImport(
            props.users.filter((filtered) =>
              results.data.some((u) => u.cedula === filtered.documentId)
            )
          )

          // Parsed Data Response in array format
          // setParsedData(results.data);

          // Filtered Column Names
          // setTableRows(rowsArray[0]);

          // Filtered Values
          // setValues(valuesArray);

          // results.data.forEach((row1) => {
          //   DataListApplicant(row1.cedula)
          // })

          setDisabledMasive(false)
        },
      })
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    let n = 0

    if (rowsImport.length > 0) {
      handleToggle()

      rowsImport.forEach((items) => {
        const dataValues = {
          userId: items.id,
          testId: props.id.id,
          managePasswords: props.id.managePasswords,
        }

        axios
          .post(`${urlServices}tests-users`, dataValues, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {
            n++
            if (rowsImport.length === n) {
              props.callback()
              setMensaje('Candidato(s) asignado(s) con éxito.')
              setOpenAlert(true)
              setError('success')
              setRowsImport([])
              setDisabledMasive(true)
              handleCloseBackdrop()
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
          })
      })
    } else {
      setMensaje(
        'No existen candidatos disponibles que coincidan con los datos del archivo.'
      )
      setOpenAlert(true)
      setError('warning')
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleCloseBackdrop = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <div className={classes.cardBrancheViewTitle}>
              <Grid container spacing={1}>
                {errorServer ? (
                  <Grid item xs={12} sm={12}>
                    <Alert severity="error">
                      Error en la consulta con sel servidor.
                    </Alert>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} md={12}>
                  <Alert severity="warning">
                    <AlertTitle>Para la asignación masiva:</AlertTitle>
                    <strong>
                      LAS CÉDULAS DEBEN ESTAR PREVIAMENTE REGISTRADAS.
                    </strong>
                    <br></br>- La columna&nbsp;
                    <strong>cédula</strong> es obligatoria, debe agregar las
                    cédula de los candidatos que serán asignadas a esta prueba.
                    <br></br>- Al finalizar el archivo excel, deberá ir a{' '}
                    <strong>"ARCHIVO y GUARDAR COMO"</strong>, seleccionar el
                    formato del archivo tipo&nbsp;
                    <strong>
                      "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{' '}
                    </strong>
                    y <strong>"GUARDAR"</strong>.
                  </Alert>
                </Grid>
                <Grid item xs={12} md={12}>
                  <center>
                    <input
                      accept=".csv"
                      style={{ display: 'none' }}
                      onChange={changeHandler}
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="filecsv"
                    />
                    <a
                      href={'/documents/csvassignmasivamente.csv'}
                      download="csvassignmasivamente.csv"
                    >
                      <Button
                        className={classes.buttonLeftSteps2}
                        startIcon={<SaveAlt />}
                      >
                        Descargar formato
                      </Button>
                    </a>
                    <label htmlFor="contained-button-file">
                      <Button
                        className={classes.buttonContactGreen}
                        component="span"
                      >
                        Subir documento
                      </Button>
                    </label>
                    <br></br>
                    <br></br>
                    <Button
                      className={classes.buttonSubmit2}
                      onClick={submitForm}
                      disabled={disabledMasive}
                      startIcon={<Save />}
                    >
                      Guardar
                    </Button>
                    <Backdrop className={classesBackdrop.backdrop} open={open}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </center>
                </Grid>
              </Grid>
            </div>
          </Fragment>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
          >
            <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
              <Alert
                onClose={handleClose}
                severity={error}
                elevation={6}
                variant="filled"
              >
                {mensaje}
              </Alert>
            </Slide>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
