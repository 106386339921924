import React, { useEffect, Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Grid from '@material-ui/core/Grid'
import { Animated } from 'react-animated-css'
import {
  BubbleChart,
  Create,
  AlarmOn,
  ArrowDropDown,
  ArrowDropUp,
} from '@material-ui/icons'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Card from 'components/Card/Card.js'
import CardIcon from 'components/Card/CardIcon.js'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

import welcome from 'assets/img/welcomeadmin.png'

import TestsDone from 'views/Admin/TestsDone'
import PendingTestsList from 'views/Admin/PendingTestsList'
import PendingTestsListPsychometrics from 'views/Admin/PendingTestsListPsychometrics'
import TestsDonetPsychometrics from 'views/Admin/TestsDonetPsychometrics'
import Test from 'views/AdminInstitution/Test.js'
import ApplicantsTest from 'views/AdminInstitution/ApplicantsTest.js'

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js'

const useStyles = makeStyles(styles)

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '5px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails)

export default function DashboardAdminUser() {
  const classes = useStyles()
  const [openTest, setopenTest] = useState(false)
  const [openTestApplicants, setopenTestApplicants] = useState(false)
  const [expanded, setExpanded] = useState('')
  const [testDone, setTestDone] = useState(false)
  const [testDonePsychometrics, setTestDonePsychometrics] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [error, setError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let usert_id = localStorage.getItem('id')
  let keyAuthorization = localStorage.getItem('Session')

  const [values, setValues] = useState({
    tests: '',
    jobsUserTpm: '',
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(`${urlServices}users/${usert_id}/tests/count`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setValues({
          tests: response.data.tests,
          jobsUserTpm: response.data.jobsUserTpm,
        })
      })
      .catch((e) => {
        if (e.response.status === 404) {
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setMensaje('Error en la consulta con el servidor')
          setError('error')
          setOpenAlert(true)
        }
      })
  }, [urlServices, keyAuthorization, keyAplication])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleOpenTests = () => () => {
    setopenTest(true)
  }

  const handleOpenTestsApplicants = () => () => {
    setopenTestApplicants(true)
  }

  if (openTest) {
    return <Test />
  }

  if (openTestApplicants) {
    return <ApplicantsTest />
  }

  const RefreshTestDone = () => {
    setTestDone(true)
  }

  const refresh = () => {
    setTestDone(false)
  }

  const RefreshTestDonePsychometrics = () => {
    setTestDonePsychometrics(true)
  }

  const RefreshPsychometrics = () => {
    setTestDonePsychometrics(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      {localStorage.getItem('profile_id') === '3' ||
      localStorage.getItem('profile_id') === '4' ||
      localStorage.getItem('profile_id') === '5' ? (
        <div className={classes.carDashboard}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <div className={classes.titleCard}>
                  <BubbleChart className={classes.iconTheme} /> Bienvenid@ al
                  panel administrativo
                </div>
                <br></br>
                <div className={classes.fontParagraph}>
                  <b>PSICOMÉTRICOS ECUADOR</b> es un Experto en Provisión de Técnicas para
                  la Selección de Personal, mejoramiento continuo y el
                  asesoramiento durante el proceso; además del manejo del riesgo
                  que esto representa.
                </div>
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} style={{ margin: '40px 0' }}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <img src={welcome} alt="welcome" className={classes.cardImg} />
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Animated
                animationIn="bounceIn"
                animationInDuration={2000}
                isVisible={true}
              >
                <Card>
                  {' '}
                  <ListItem button onClick={handleOpenTests()}>
                    <CardIcon color="success">
                      <Create
                        fontSize="large"
                        className={classes.iconWhite}
                      ></Create>
                    </CardIcon>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="textPrimary"
                    >
                      Listado de pruebas técnicas
                    </Typography>
                  </ListItem>
                </Card>
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Animated
                animationIn="bounceIn"
                animationInDuration={2000}
                isVisible={true}
              >
                <Card>
                  {' '}
                  <ListItem button onClick={handleOpenTestsApplicants()}>
                    <CardIcon color="success">
                      <AlarmOn
                        fontSize="large"
                        className={classes.iconWhite}
                      ></AlarmOn>
                    </CardIcon>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="textPrimary"
                    >
                      Estado de pruebas de candidatos
                    </Typography>
                  </ListItem>
                </Card>
              </Animated>
            </GridItem>
          </GridContainer>
        </div>
      ) : localStorage.getItem('profile_id') === '2' ? (
        <div className={classes.carDashboard}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <div className={classes.titleCard}>
                  <BubbleChart className={classes.iconTheme} /> Bienvenid@ al
                  panel administrativo
                </div>
                <br></br>
                <div className={classes.fontParagraph}>
                  <b>PSICOMÉTRICOS ECUADOR</b> es un Experto en Provisión de Técnicas para
                  la Selección de Personal, mejoramiento continuo y el
                  asesoramiento durante el proceso; además del manejo del riesgo
                  que esto representa.
                </div>
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} style={{ margin: '40px 0' }}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <img src={welcome} alt="welcome" className={classes.cardImg} />
              </Animated>
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <div className={classes.carDashboard}>
          <Grid container>
            <GridItem xs={12} md={12}>
              <div className={classes.titleCard}>
                <BubbleChart className={classes.iconTheme} /> ¡Bienvenid@!
              </div>
              <div className={classes.lineBottom}> </div>
              <br></br>

              <div className={classes.fontParagraph}>
                Pruebas pendientes y finalizadas que tiene asignado su usuario.
              </div>
              <br></br>
            </GridItem>
            <GridItem xs={12} md={12}>
              {values.tests > 0 ? (
                <Accordion
                  square
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <b
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel1' ? (
                        <ArrowDropUp className={classes.iconTheme} />
                      ) : (
                        <ArrowDropDown className={classes.iconTheme} />
                      )}
                      <span>Pruebas técnicas</span>
                    </b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <GridItem xs={12} md={12} sm={12}>
                        <PendingTestsList callBackTestDone={RefreshTestDone} />
                      </GridItem>
                      <GridItem xs={12} md={12} sm={12}>
                        <TestsDone
                          refreshTestDone={testDone}
                          refresh={refresh}
                        />
                      </GridItem>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : (
                ''
              )}

              {values.jobsUserTpm > 0 ? (
                <Accordion
                  square
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <b
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel2' ? (
                        <ArrowDropUp className={classes.iconTheme} />
                      ) : (
                        <ArrowDropDown className={classes.iconTheme} />
                      )}
                      <span>Pruebas psicométricas</span>{' '}
                    </b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <GridItem xs={12} md={12} sm={12}>
                        <PendingTestsListPsychometrics
                          callBackTestDonePsychometrics={
                            RefreshTestDonePsychometrics
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} md={12} sm={12}>
                        <TestsDonetPsychometrics
                          RefreshTestDonePsychometrics={testDonePsychometrics}
                          refreshPsychometrics={RefreshPsychometrics}
                        />
                      </GridItem>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : (
                ''
              )}
              <GridItem xs={12} md={12} style={{ margin: '40px 0' }}>
                <Animated
                  animationIn="fadeIn"
                  animationInDuration={200}
                  isVisible={true}
                >
                  <center>
                    <img src={welcome} alt="welcome" style={{ width: 300 }} />
                  </center>
                </Animated>
              </GridItem>
            </GridItem>
          </Grid>
        </div>
      )}

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  )
}
